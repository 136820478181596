/* eslint-disable no-param-reassign */
import * as validations from './validations/validations'
import * as validators from './validations/validators'

const AndrenaUtilitaryLibrary = {
  validations,
  validators,
}

function install(Vue) {
  Vue.prototype.$aul = AndrenaUtilitaryLibrary
  return Vue
}

export default { install, ...AndrenaUtilitaryLibrary }
