import jwt from 'jsonwebtoken'
import eventBus from '@/libs/event-bus'
import authDefaultConfig from './authDefaultConfig'

function emitAllMessages(messages) {
  messages.success.map(message => eventBus.$emit('message:success', message))
  messages.warning.map(message => eventBus.$emit('message:warning', message))
  messages.error.map(message => eventBus.$emit('message:error', message))
  messages.info.map(message => eventBus.$emit('message:info', message))
}

export default class AuthService {
  axiosInstance = null

  authConfig = { ...authDefaultConfig }

  constructor(axiosInstance, authConfigOverride) {
    this.axiosInstance = axiosInstance
    this.authConfig = { ...this.authConfig, ...authConfigOverride }

    axiosInstance.interceptors.response.use(
      response => {
        if (!response) return response
        const { data } = response

        if (data && data.messages) {
          emitAllMessages(data.messages)
        }

        if (response && data.operation_status) {
          this.setToken(data.accessToken)
          localStorage.setItem('userData', JSON.stringify(data.userData))
          // this.$ability.update(loginResponse.userData.ability)
        }
        return response
      },
      error => {
        const { response } = error
        if (response && response.data && response.data.messages) {
          emitAllMessages(response.data.messages)
        }
        throw new Error(error)
      },
    )
  }

  getToken() {
    return localStorage.getItem(this.authConfig.storageTokenKeyName)
  }

  async validateToken() {
    const userToken = localStorage.getItem(this.authConfig.storageTokenKeyName)
    const publicKey = process.env.VUE_APP_PUBLIC_KEY_JWT.replace(/\\n/g, '')

    if (!userToken || !publicKey) return false

    try {
      return !!(await jwt.verify(userToken, publicKey, { algorithms: ['RS256'] }))
    } catch (err) {
    // eslint-disable-next-line no-console
      console.error(err)
      return false
    }
  }

  setToken(value) {
    localStorage.setItem(this.authConfig.storageTokenKeyName, value)
  }

  login(...args) {
    return this.axiosInstance.post(this.authConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosInstance.post(this.authConfig.registerEndpoint, ...args)
  }

  logout(customMessage) {
    localStorage.removeItem(this.authConfig.storageTokenKeyName)
    eventBus.$emit('message:error', customMessage)
  }
}
