/* eslint-disable no-param-reassign */
import mitt from 'mitt'

const emitter = mitt()

function install(Vue) {
  Vue.prototype.$eb = emitter
  Vue.prototype.$g_on = emitter.on
  Vue.prototype.$g_emit = emitter.emit
  Vue.prototype.$g_off = emitter.off
  Vue.prototype.$g_all = emitter.all
  return Vue
}

export default {
  install,
  $eb: emitter,
  $emit: emitter.emit,
  $on: emitter.on,
  $off: emitter.off,
  $all: emitter.all,
}
