import axios from 'axios'

// console.log(process.env.VUE_APP_AUTH_ENDPOINT)
export default axios.create({
  baseURL: process.env.VUE_APP_AUTH_ENDPOINT,
  // headers: {
  //   'Access-Control-Allow-Origin': '*/*',
  //   'Access-Control-Allow-Methods': 'POST, GET',
  //   'Access-Control-Allow-Headers': '*',
  //   'Access-Control-Max-Age': '86400',
  // },
})
