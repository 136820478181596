import authInstance from '@/auth/authInstance'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/libs/i18n'
import publicRoutes from './publicRoutes.json'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/miscellaneous/ComingSoon.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Home',
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/authentication/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/recover-password',
      name: 'recover-password',
      component: () => import('@/views/authentication/RecoverPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  // Check authentication
  const isPublicPath = publicRoutes.includes(to.path)
  const isAuthenticated = await authInstance.validateToken()
  if (!isPublicPath && !isAuthenticated) {
    store.commit('app/PRE_LOGIN_DESTINATION', to.fullPath)
    return next('/login')
  }

  // Checks language
  if (to.query.lang && i18n.availableLocales.includes(to.query.lang)) {
    i18n.locale = to.query.lang
    localStorage.setItem('lang', to.query.lang)
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
