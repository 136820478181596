import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import AuthService from '@/auth/authService'
import authInstance from '@/auth/axiosAuthInstance'

import i18n from '@/libs/i18n'
import EventBus from '@/libs/event-bus'
import AndrenaUtilitaryLibrary from '@/libs/aul'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

Vue.prototype.$auth = new AuthService(authInstance)

// Andrena utilitary library
Vue.use(AndrenaUtilitaryLibrary)

// Bootstrap Vue
Vue.use(BootstrapVue)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(EventBus)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
